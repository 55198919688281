import { useEffect } from "react";

/**
 * This is a hook that fetches directions from the server
 * @param {function} setDirections
 */
function useFetchDirections(height, setDirections, cachedData) {
    useEffect(() => {
        // If we have cached data, use it
        if (cachedData[height] !== undefined) {
            console.log("STEP 2: Using Cached Directions...");
            setDirections(cachedData[height]);
            return;
        }
    }, [height, cachedData]);
}

export default useFetchDirections;