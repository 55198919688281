import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  IconButton,
  Divider,
  Paper,
} from '@mui/material';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

function ControlPanel({ setHeight, setTime, resetView, setLoading }) {
  const [currentHeight, setCurrentHeight] = useState(20);
  const [currentTime, setCurrentTime] = useState(0);
  const [initialHeight, setInitialHeight] = useState(20);
  const [initialTime, setInitialTime] = useState(0);
  const [now, setNow] = useState(new Date());
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const maxForecastTime = 5;

  useEffect(() => {
    const timer = setInterval(() => setNow(new Date()), 60000);
    return () => clearInterval(timer);
  }, []);

  const adjustForecast = (adjustment) => {
    const newTime = currentTime + adjustment;
    if (newTime <= maxForecastTime && newTime >= 0) {
      setCurrentTime(newTime);
    }
  };

  const formatTime = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to 0

    const year = adjustedDate.getFullYear();
    const month = String(adjustedDate.getMonth() + 1).padStart(2, '0');
    const day = String(adjustedDate.getDate()).padStart(2, '0');
    const hours = String(adjustedDate.getHours()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:00`;
  };

  const forecastTime = new Date(now.getTime() + currentTime * 3600000);
  const isChanged = currentHeight !== initialHeight || currentTime !== initialTime;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: isPanelVisible ? 0 : '-250px',
        transition: 'left 0.3s',
        zIndex: 2,
        width: '250px',
        height: '100vh',
      }}
    >
      <IconButton
        onClick={() => setIsPanelVisible(!isPanelVisible)}
        sx={{
          position: 'absolute',
          top: 16,
          left: '250px',
          transition: 'left 0.3s',
          zIndex: 3,
          color: 'white',
        }}
      >
        {isPanelVisible ? <ArrowBackIos /> : <ArrowForwardIos />}
      </IconButton>

      <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
        <Stack spacing={3} className="control-panel" alignItems="center" justifyContent="start" sx={{ height: '100%' }}>
          <Typography variant="h6">Wind Platform</Typography>

          <Divider />

          <Stack direction="column" spacing={2} justifyContent="start" sx={{ flexGrow: 1 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Height</InputLabel>
              <Select
                value={currentHeight}
                onChange={(e) => setCurrentHeight(e.target.value)}
                label="Height"
              >
                {[20, 50, 90].map((height) => (
                  <MenuItem key={height} value={height}>
                    {height}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack direction="column" alignItems="center" spacing={4}>
              <Typography>Now: {formatTime(now)}</Typography>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Button variant="outlined" onClick={() => adjustForecast(-1)}>{'<'}</Button>
                <Typography>+{currentTime} hour</Typography>
                <Button variant="outlined" onClick={() => adjustForecast(1)}>{'>'}</Button>
              </Stack>
              <Typography>Forecast: {formatTime(forecastTime)}</Typography>
            </Stack>

            <Divider />

            <Stack spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  console.log('Resetting view');
                  if (resetView) {
                    resetView();
                  }
                }}
                fullWidth
              >
                Reset View
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!isChanged}
                onClick={() => {
                  setLoading(true);
                  setHeight(currentHeight);
                  setTime(currentTime + 1);
                  setInitialHeight(currentHeight);
                  setInitialTime(currentTime);
                }}
                fullWidth
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </div>
  );
}

export default ControlPanel;