import { useEffect } from "react";

/**
 * This is a hook that fetches positions from the server
 * @param {function} setPositions
 */
function useFetchPositions(setPositions) {
    useEffect(() => {
        // STEP 2: Fetch the positions from the server
        console.log("STEP 1: Loading Real Positions from Server...");
        let url;
        if(process.env.NODE_ENV === 'development'){
            url = `http://localhost:5000/positions`;
            console.log("url: ", url);
        }else{
            url = `https://server.urbanwindhk.com/positions`;
        }
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log("loaded positions: ", data.length);
                setPositions(data);
            })
            .catch(error => console.error('Error fetching positions:', error));
    }, []);
}

export default useFetchPositions;