// App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import useFetchPositions from './hooks/useFetchPositions';
import useFetchDirections from './hooks/useFetchDirections';
import WindVisualizer from './components/WindVisualizer';
import ControlPanel from './components/ControlPanel';
import WindVisualizerTest from './components/WindVisualizerTest';

function App() {
  return (
    <div>
      <WindVisualizer/>
    </div>
  );
}

export default App;