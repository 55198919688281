import { useEffect } from "react";

/**
 * This is a hook that fetches directions from the server
 * @param {function} setDirections
 */
function useFetchAndCacheData(cachedData, setCachedData) {
    useEffect(() => {
        const fetchDataSequentially = async () => {
            let heightList = [20, 50, 90];
            for (let height of heightList) {
                // If we have cached data, use it
                if (cachedData[height] !== undefined) {
                    console.log("Already Cached");
                    continue;
                }
                console.log("STEP 2: Loading Real Directions from Server...");
                try {
                    let url;
                    if(process.env.NODE_ENV === 'development'){
                        url = `http://localhost:5000/data/${height}`;
                    }else{
                        url = `https://server.urbanwindhk.com/data/${height}`;
                    }
                    const response = await fetch(url);
                    const data = await response.json();
                    console.log("Caching: ", data);
                    // Cache the data
                    setCachedData((previousCachedData) => {
                        return {
                            ...previousCachedData,
                            [height]: data
                        };
                    });
                } catch (error) {
                    console.error('Error fetching directions:', error);
                }
            }
        };

        fetchDataSequentially();
    }, [cachedData, setCachedData]);
}

export default useFetchAndCacheData;