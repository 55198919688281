import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material'; // Import the CircularProgress component
import '@kitware/vtk.js/favicon';
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import '@kitware/vtk.js/Rendering/Profiles/Glyph';

import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkConeSource from '@kitware/vtk.js/Filters/Sources/ConeSource';
import vtkGlyph3DMapper from '@kitware/vtk.js/Rendering/Core/Glyph3DMapper';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPlaneSource from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import vtkScalarBarActor from '@kitware/vtk.js/Rendering/Core/ScalarBarActor';
import useFetchPositions from '../hooks/useFetchPositions';
import useFetchDirections from '../hooks/useFetchDirections';
import ControlPanel from './ControlPanel';
import useFetchAndCacheData from '../hooks/useFetchAndCacheData';

function WindVisualizer({ windSpeedRange = [0, 6] }) {
    
    const [height, setHeight] = useState(20);
    const [time, setTime] = useState(1);
    const [positions, setPositions] = useState([]);
    const [cachedData, setCachedData] = useState([]);
    const [directions, setDirections] = useState([]);
    const [firstRendered, setFirstRendered] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        if(loading && positions.length > 0 &&  Object.keys(directions).length > 0 > 0 && firstRendered){
            console.log("done loading");
            setTimeout(()=>{
                setLoading(false);
            }, 800);
        }
    }, [positions, directions, time,firstRendered, loading]);
    const rendererRef = useRef(null);
    const rendererWindowRef = useRef(null);
    const loadedSTL = useRef(false);

    useFetchPositions(setPositions);
    useFetchAndCacheData(cachedData, setCachedData);
    useFetchDirections(height, setDirections, cachedData);

    function resetView() {
        rendererRef.current.getActiveCamera().set({ position: [0, 0, 10000], viewUp: [0, 1, 0], focalPoint: [0, 0, 0] });
        rendererRef.current.resetCamera();
        rendererWindowRef.current.render();
    }

    useEffect(() => {
        const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
            background: [0.2, 0.3, 0.4],
        });
        const renderer = fullScreenRenderer.getRenderer();
        const renderWindow = fullScreenRenderer.getRenderWindow();

        rendererRef.current = renderer;
        rendererWindowRef.current = renderWindow;

    }, []);

    useEffect(() => {
        if (loadedSTL.current || !rendererRef.current) return;

        const loadSTL = (url, position) => {
            const stlReader = vtkSTLReader.newInstance();
            stlReader.setUrl(url).then(() => {
                const stlMapper = vtkMapper.newInstance();
                const stlActor = vtkActor.newInstance();
                stlMapper.setInputConnection(stlReader.getOutputPort());
                stlMapper.setScalarVisibility(false);
                stlActor.setMapper(stlMapper);
                stlActor.setPosition(...position);
                rendererRef.current.addActor(stlActor);
                console.log("done loading stl: ", url);
                
                if (!firstRendered) {
                    resetView();
                    setFirstRendered(true);
                }

            }).catch(error => {
                console.error(`Error loading ${url}:`, error);
            });
        };

        let urlBase;
        if (process.env.NODE_ENV === 'development') {
            urlBase = "https://wind-simulator-data.s3-ap-east-1.amazonaws.com";
        } else {
            urlBase = "city";
        }
        loadSTL(`${urlBase}/terrain.stl`, [0, 0, -1]);
        loadSTL(`${urlBase}/buildings.stl`, [0, 0, 0]);

        loadedSTL.current = true;
    }, []);


    useEffect(() => {
        if (!positions || !directions[time] || positions.length === 0 || directions.length === 0) {
            return;
        }

        const flatPositions = positions.flat();
        const flatDirections = directions[time].flat();
        const renderer = rendererRef.current;
        const renderWindow = rendererWindowRef.current;

        const polyData = vtkPolyData.newInstance();
        const appendFilter = vtkAppendPolyData.newInstance();

        if (flatPositions.length % 3 === 0) {
            const points = new Float32Array(flatPositions.map((value, index) => 
                index % 3 === 2 ? height : value));
            polyData.getPoints().setData(points, 3);
        } else {
            console.error('Positions array length is not a multiple of 3');
            return;
        }

        if (flatDirections.length % 3 === 0) {
            const orientationArray = vtkDataArray.newInstance({
                numberOfComponents: 3,
                values: flatDirections,
                name: 'flatDirections',
            });
            polyData.getPointData().setVectors(orientationArray);

            const magnitudes = new Float32Array(flatDirections.length / 3);
            for (let i = 0; i < flatDirections.length; i += 3) {
                const vx = flatDirections[i];
                const vy = flatDirections[i + 1];
                const vz = flatDirections[i + 2];
                magnitudes[i / 3] = Math.sqrt(vx * vx + vy * vy + vz * vz);
            }

            const magnitudeArray = vtkDataArray.newInstance({
                numberOfComponents: 1,
                values: magnitudes,
                name: 'magnitude',
            });
            polyData.getPointData().addArray(magnitudeArray);
            polyData.getPointData().getArrayByName('magnitude').setRange(windSpeedRange[0], windSpeedRange[1]);
            polyData.getPointData().setActiveScalars('magnitude');
        } else {
            console.error('flatDirections array length is not a multiple of 3');
            return;
        }

        appendFilter.setInputData(polyData);

        const mapper = vtkGlyph3DMapper.newInstance();
        const actor = vtkActor.newInstance();

        const coneSource = vtkConeSource.newInstance();
        coneSource.setResolution(12);
        coneSource.setHeight(3);
        coneSource.setRadius(0.2);

        mapper.setInputConnection(appendFilter.getOutputPort());
        mapper.setSourceConnection(coneSource.getOutputPort());
        mapper.setScaleFactor(5);
        mapper.setScaleModeToScaleByConstant();
        mapper.setOrientationModeToDirection();
        mapper.setOrientationArray('flatDirections');

        const orientationArray = vtkDataArray.newInstance({
            numberOfComponents: 3,
            values: flatDirections.map((value, index) => (index % 3 === 2 ? 0 : value)),
            name: 'flatDirections',
        });
        polyData.getPointData().setVectors(orientationArray);

        const colorTransferFunction = vtkColorTransferFunction.newInstance();
        colorTransferFunction.addRGBPoint(windSpeedRange[0], 0.0, 0.0, 1.0);
        colorTransferFunction.addRGBPoint(windSpeedRange[1] / 2, 0.0, 1.0, 0.0);
        colorTransferFunction.addRGBPoint(windSpeedRange[1], 1.0, 0.0, 0.0);
        mapper.setLookupTable(colorTransferFunction);
        mapper.setScalarVisibility(false);

        actor.setMapper(mapper);
        renderer.addActor(actor);
        let planeActor = null;
        let scalarBarActor = null;

        const addScalarPlane = () => {
            const point1 = [flatPositions[0], flatPositions[1], height];
            const point2 = [flatPositions[flatPositions.length - 3], flatPositions[flatPositions.length - 2], height];

            const resolutionScale = 1;
            const planeSource = vtkPlaneSource.newInstance({
                xResolution: 320 * resolutionScale,
                yResolution: 320 * resolutionScale,
                origin: point1,
                point1: [point2[0], point1[1], height],
                point2: [point1[0], point2[1], height],
            });

            const windSpeeds = polyData.getPointData().getArrayByName('magnitude').getData();

            const vtkWindDataArray = vtkDataArray.newInstance({
                name: 'magnitude',
                values: windSpeeds,
                numberOfComponents: 1,
            });

            planeSource.getOutputData().getPointData().addArray(vtkWindDataArray, 1);
            planeSource.getOutputData().getPointData().setActiveScalars('magnitude');

            planeSource.getOutputData().getCellData().addArray(vtkWindDataArray, 1);
            planeSource.getOutputData().getCellData().setActiveScalars('magnitude');

            const planeMapper = vtkMapper.newInstance();
            planeMapper.setInputConnection(planeSource.getOutputPort());

            planeMapper.set({
                interpolateScalarsBeforeMapping: true,
                colorMode: ColorMode.DEFAULT,
                scalarMode: ScalarMode.DEFAULT,
                useLookupTableScalarRange: true,
                scalarVisibility: true,
            });

            const lookupTable = vtkColorTransferFunction.newInstance();
            lookupTable.addRGBPoint(0, 0.0, 0.0, 1.0);
            lookupTable.addRGBPoint(1.5, 0.0, 1.0, 1.0);
            lookupTable.addRGBPoint(3, 0.0, 1.0, 0.0);
            lookupTable.addRGBPoint(4.5, 1.0, 1.0, 0.0);
            lookupTable.addRGBPoint(6, 1.0, 0.0, 0.0);

            lookupTable.setMappingRange(windSpeedRange[0], windSpeedRange[1]);
            planeMapper.setLookupTable(lookupTable);

            planeActor = vtkActor.newInstance();
            planeActor.getProperty().setPointSize(2);
            planeActor.getProperty().setInterpolationToFlat();
            planeActor.setMapper(planeMapper);

            renderer.addActor(planeActor);

            scalarBarActor = vtkScalarBarActor.newInstance();
            renderer.addActor(scalarBarActor);
            scalarBarActor.setScalarsToColors(lookupTable);
            scalarBarActor.setAxisLabel('Wind Speed [m/s]');
            return planeActor;
        };

        addScalarPlane();
        renderWindow.render();

        return () => {
            renderer.removeActor(actor);
            renderer.removeActor(planeActor);
            renderer.removeActor(scalarBarActor);
            renderWindow.render();
        }
    }, [positions, directions, time, windSpeedRange, height]);

    return (
        <>
            {loading && (
                <div className="loading-overlay">
                    Loading... <span className="loading-icon"><CircularProgress className="fast-spinner" /></span>
                </div>
            )}
            <ControlPanel setHeight={setHeight} setTime={setTime} resetView={resetView} setLoading={setLoading} />
            <div id="vtk-container" />
        </>
    );
}

export default WindVisualizer;